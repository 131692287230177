<template>
  <div>

    <v-img v-show='!showVideo' :src="getAssetImg('photos/banner-pic.webp')" contain>
    </v-img>

    <div v-show='showVideo'>
      <video class="video" autoplay muted playsinline loop>
        <source :src="videoSrc" type="video/mp4">
      </video>
    </div>

  </div>
</template>

<script>
export default {
  name: "LifestyleBanner",
  props: ['showVideo'],
  computed: {
    height() {
      return this.$vuetify.breakpoint.smAndDown ? 'height: 25vh;' : 'height: 90vh;';
    },
    videoSrc() {
      return this.getAssetVid('video/banner.mp4')
    }

  }
};
</script>

<style scoped>

.video {
    display: block;
    width: 100%;
    max-height: 75vh;
    object-fit: cover;
    transition: transform .2s ease-in-out;
}


</style>
