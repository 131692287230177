<template>
  <v-img :src="getAssetImg(img)" :style="height" >
  </v-img>
</template>

<script>
export default {
  name: "ProductLifestyle",
  props: {
    img: {
      type: String
    }
  },
  computed: {
    height() {
      return this.$vuetify.breakpoint.smAndDown ? 'height: 30vh;' : 'height: 90vh;';
    }
  }
};
</script>

<style scoped>
</style>
