<template>
  <div>
    <v-row v-if="posts.length">
      <v-col align="center">
        <p class="text-h3 mt-8 primary--text font-weight-bold">
          Social Feed
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(p,i) in posts.slice(0,12)" :key="i" cols="10" offset="1" sm="4" offset-sm="0" md="3" :class="columnClass">
        <a :href="p.permalink" target="_blank">
          <v-img :src="p.thumbnail_url ? p.thumbnail_url : p.media_url" height="300" ></v-img>
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SocialFeed",
  data() {
    return {
      posts: []
    }
  },
  created() {
    axios.get("/api/social/feed")
      .then(response => {
        this.posts = response.data
      })
  },
  computed: {
    columnClass() {
      return this.$vuetify.breakpoint.xsOnly ? '' : 'ma-0 pa-0';
    }
  }
}
</script>

<style scoped>

</style>