<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="128"
      ></v-progress-circular>
    </v-overlay>
    <main-age-gateway-dialog v-model="ageGatewayOpen"></main-age-gateway-dialog>
    <lifestyle-banner :show-video="!ageGatewayOpen && !overlay"></lifestyle-banner>
    <v-row>
      <v-col cols="10" offset="1" md="12" offset-md="0">
        <product-lineup></product-lineup>
      </v-col>
    </v-row>
    <product-lifestyle class="mt-16" :height="850"
                       :img="'photos/LIFESTYLE/LIFESTYLE/GoodBevCo-2.webp'"></product-lifestyle>
    <v-container>
      <v-row>
        <v-col cols="12" xl="10" offset-xl="1">
          <lifestyle-certifications class="mt-16"></lifestyle-certifications>
          <social-feed></social-feed>
          <fan-favorites></fan-favorites>
        </v-col>
      </v-row>
    </v-container>
    <product-lifestyle class="mt-16" :height="850"
                       :img="'photos/LIFESTYLE/BANNER/GoodBevCo.webp'"></product-lifestyle>
    <div style="background-color: #EA2B8A">
      <v-container>
        <v-row>
          <v-col cols="12" xl="10" offset-xl="1">
            <contact-us></contact-us>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import LifestyleBanner from "@/components/main/LifestyleBanner";
import ProductLineup from "@/components/main/ProductLineup";
import ProductLifestyle from "@/components/main/ProductLifestyle";
import LifestyleCertifications from "@/components/main/LifestyleCertifications";
import SocialFeed from "@/components/main/SocialFeed";
import FanFavorites from "@/components/main/FanFavorites";
import ContactUs from "@/components/main/ContactUs";
import MainAgeGatewayDialog from "@/components/main/MainAgeGatewayDialog";

export default {
  name: "MainView",
  components: {
    MainAgeGatewayDialog,
    ContactUs,
    FanFavorites,
    SocialFeed,
    LifestyleCertifications,
    ProductLifestyle,
    ProductLineup,
    LifestyleBanner
  },
  data() {
    return {
      ageGatewayOpen: false,
      overlay: true,
    }
  },
  created() {
    setTimeout(() => {
      this.overlay = false
      this.ageGatewayOpen = true
    },1000);
  }
}
</script>

<style scoped>

</style>