<template>
  <div>
    <v-row class="mt-6">
      <v-col cols="12" md="6">
        <v-row>
          <v-col>
            <span class="text-h3 primary--text font-weight-bold">
              Lifestyle
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p class="text-body-1">
              Where the sun shines all year, creativity runs rampant; the people are tanned, toned, and just
              good-looking; others call it the land of dreams; we call it home. California is the sole inspiration for
              the Good Beverage Company, where living life on-the-go comes with the territory. Rather you are on the
              beach, lake, river, hike, sitting by the pool, or just hanging in the backyard, we will see you out there!
            </p>
          </v-col>
        </v-row>
        <v-row class="mt-16">
          <v-col>
            <span class="text-h3 accent--text font-weight-bold">
              Certifications
            </span>
          </v-col>
        </v-row>
        <v-row class="text-uppercase text-body-2">
          <v-col cols="6" lg="3" align="center">
            <v-avatar size="75">
              <img
                :src="getAssetImg('logo/claims/Gluten_Free_NAVY.png')"
                alt="placeholder"
              >
            </v-avatar>
            <br>
            Gluten Free
          </v-col>
          <v-col cols="6" lg="3" align="center">
            <v-avatar size="75">
              <img
                :src="getAssetImg('logo/claims/All_Natural_NAVY.png')"
                alt="placeholder"
              >
            </v-avatar>
            <br>
            All Natural
          </v-col>
          <v-col cols="6" lg="3" align="center">
            <v-avatar size="75">
              <img
                :src="getAssetImg('logo/claims/Vegan_NAVY.png')"
                alt="placeholder"
              >
            </v-avatar>
            <br>
            Vegan
          </v-col>
          <v-col cols="6" lg="3" align="center">
            <v-avatar size="75">
              <img
                :src="getAssetImg('logo/claims/PleaseRecycle_Navy.png')"
                alt="placeholder"
              >
            </v-avatar>
            <br>
            Please Recycle
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-img :src="getAssetImg('photos/LIFESTYLE/LIFESTYLE/GoodBevCo-15.webp')" height="600" contain></v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "LifestyleCertifications"
}
</script>

<style scoped>

</style>