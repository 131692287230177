<template>
  <div>
    <v-row>
      <v-col align="center">
        <p class="text-h3 mt-8 primary--text font-weight-bold">
          Fan Favorites
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="(product,i) in products" :key="i" md="6" align="center">
        <a :href="product.url" target="_blank">
          <v-img :src="getAssetImg(product.img)" height="400" contain></v-img>
          <v-btn color="primary">Shop Now</v-btn>
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "FanFavorites",
  data() {
    return {
      products: [
        {
          img: 'photos/STUDIO/BOX/BoxBerries.png',
          url: 'https://www.hitimewine.net/good-bev-co.-berries-citrus-12pk-200ml-122269'
        },
        {
          img: 'photos/STUDIO/BOX/BoxMarg.png',
          url: 'https://www.hitimewine.net/good-bev-co.-margarita-12pk-200ml-122268'
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>