<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card :img="getAssetImg('photos/21GatewayBackground.jpg')">
      <v-card-text>
        <v-container>
          <v-row class="mt-16">
            <v-col align="center">
              <v-img
                :src="getAssetImg('logo/Logo_Regular.png')"
                contain
                max-width="200px"
                class="mr-4"
              ></v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <p class="text-sm-h5 text-xs-h6 primary--text font-weight-bold">
                Gotta be 21+ to be <span class="font-italic">good </span>!
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center">
              <v-row>
                <v-col>
                  <v-btn
                    v-bind="size"
                    color="primary"
                    class="white--text mr-2"
                    height="56"
                    @click.stop="show = false"
                  >
                    I'm 21 and good
                  </v-btn>
                  <v-btn
                    v-bind="size"
                    color="primary"
                    class="white--text"
                    height="56"
                    href="http://www.toysrus.com"
                  >
                    I'm not good yet
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-8">
            <v-col align="center">
              <p class="accent--text font-weight-bold black--text text-caption text-sm-body-1">
                You must be 21 or older to enter. Please drink responsibly.
                <br/>
                <span class="accent--text text-uppercase font-weight-bold black--text text-body-1 text-sm-h6">{{ year }} Good Beverage Company, INC.</span>
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col align="center">

            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MainAgeGatewayDialog",
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    size() {
      const size = {xs: 'x-small', sm: 'small', lg: 'large', xl: 'x-large'}[this.$vuetify.breakpoint.name];
      return size ? {[size]: true} : {}
    },
    year() {
      return new Date().getFullYear();
    }
  },
};
</script>

<style scoped>
</style>
