import { render, staticRenderFns } from "./ProductLifestyle.vue?vue&type=template&id=7c7721d1&scoped=true&"
import script from "./ProductLifestyle.vue?vue&type=script&lang=js&"
export * from "./ProductLifestyle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c7721d1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
