<template>
  <v-container class="container">
    <v-row class="">
      <v-col align="center">
        <v-img
          :src="getAssetImg('logo/Mustbe21.png')"
          contain
          max-width="150px"
          class="mr-4"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <p class="text-sm-h5 text-body-1 white--text font-weight-bold">
          Why Be Great When You Can Be Good... 
          <span class="font-italic">REALLY GOOD</span>
        </p>
        <p class="text-caption text-sm-body-1 font-weight-bold" style="color: #252061">
          Subscribe for latest news, events, and promotions
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        align="center"
        :cols="inputCols"
        :offset="inputOffset"
        md="8"
        offset-md="2"
        lg="4"
        offset-lg="4"
      >
        <v-row>
          <v-text-field
            v-model="email"
            placeholder="Enter your email here"
            color="#252061"
            background-color="white"
            outlined
            @keyup.enter="submit"
          ></v-text-field>
          <v-btn
            x-large
            color="white"
            class="primary--text"
            height="56"
            @click="submit"
            :loading="loading"
          >
            Submit
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col align="center">
        <p class="text-sm-h5 text-xs-h6 white--text font-weight-bold">
          {{ signUpText }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "ContactUs",
  data() {
    return {
      email: "",
      signUpText: "",
      loading: false,
      // eslint-disable-next-line
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

    };
  },
  methods: {
    submit() {
      if (!this.email || this.email.length === 0) {
        this.signUpText = "An email is required to sign up!";
      } else if(!this.reg.test(this.email)) {
        this.signUpText = "Please enter a valid email address";
      } else {
        this.signUpText = "";
        this.loading = true;
        axios
          .post("/api/mail/newsletter", {email: this.email})
          .then(() => {
            this.signUpText = "Thanks for signing up!";
            this.email = "";
          })
          .catch(() => {
            this.signUpText = "Sorry! There was an error signing you up";
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  computed: {
    inputCols() {
      return window.innerWidth < 400 ? 12 : 10;
    },
    inputOffset() {
      return window.innerWidth < 400 ? 0 : 1;
    },
  },
};
</script>

<style scoped>
.container {
  height: 400px;
}
.v-text-field--outlined >>> fieldset {
  border-color: rgba(234, 9, 138, 255);
}
</style>
