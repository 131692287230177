<template>
  <div>
    <v-row class="mt-6">
      <v-col cols="12" md="4">
        <v-row no-gutters>
          <v-col>
            <v-img
              :src="getAssetImg('logo/Logo_Regular.png')"
              max-height="150"
              max-width="150"
              style="transform: rotate(-8deg); "
              class="ml-8"
            ></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="ml-4">
            <span class="text-body-1">
             The Good Beverage Company prides itself in its unique approach to packaging while sourcing the best ingredients available to serve you the best cocktails in the best format for a truly on-the-go experience.
            </span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col align="center">
            <v-btn
              color="primary"
              class="mt-8"
              href="https://www.hitimewine.net/search.php?search_query=good+bev+co"
              target="_blank"
            >
              Shop Now
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="no-gutters mt-4">
          <v-col align="center">
            <span class="accent--text font-weight-bold text-uppercase">Made with</span>
          </v-col>
        </v-row>
        <v-row class="no-gutters">
          <v-col cols="5" offset="1">
            <v-row no-gutters>
              <v-col cols="2">
                <v-img :src="getAssetImg('logo/claims/MadeWithAmericanVodka.png')"
                       height="40"
                       width="40"
                ></v-img>
              </v-col>
              <v-col cols="9" offset="1">
                <p class="accent--text font-weight-bold mt-2">
                  American Vodka
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5" offset="1">
            <v-row no-gutters>
              <v-col cols="2">
                <v-img :src="getAssetImg('logo/claims/MadeWithPremiumTequila.png')" height="40" width="40"
                       contain></v-img>
              </v-col>
              <v-col cols="9" offset="1">
                <p class="accent--text font-weight-bold mt-2">
                  Premium Tequila
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="8" class="cursor">
        <v-row>
          <v-col md="6" @click="go('https://www.hitimewine.net/good-bev-co.-margarita-12pk-200ml-122268')">
            <v-row no-gutters class="bounce">
              <v-col v-for="(p, i) in products.slice(0,3)" :key="i" cols="4" class="ma-0 p-0">
                <v-img :src="getAssetImg(p.img)" height="300" contain></v-img>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col v-for="(p, i) in products.slice(0,3)" :key="i" cols="4" class="ma-0 p-0">
                <v-img aria-hidden="true" :src="dropShadow" style="margin-top: -60px"></v-img>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col v-for="(p, i) in products.slice(0,3)" :key="i" cols="4" class="ma-0 p-0">
                <v-img :src="getAssetImg(p.nameImg)" height="50" contain></v-img>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="6" @click="go('https://www.hitimewine.net/good-bev-co.-berries-citrus-12pk-200ml-122269')">
            <v-row no-gutters class="bounce">
              <v-col v-for="(p, i) in products.slice(3)" :key="i" cols="4" class="ma-0 p-0">
                <v-img :src="getAssetImg(p.img)" height="300" contain></v-img>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col v-for="(p, i) in products.slice(0,3)" :key="i" cols="4" class="ma-0 p-0">
                <v-img aria-hidden="true" :src="dropShadow" style="margin-top: -60px"></v-img>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col v-for="(p, i) in products.slice(3)" :key="i" cols="4" class="ma-0 p-0">
                <v-img :src="getAssetImg(p.nameImg)" height="50" contain></v-img>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ProductLineup",
  data() {
    return {
      dropShadow: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAAAwUlEQVQoz5XS3QvBUBjH8TEs2y5YspTyzg2h5FZKbkUuceUt/P/XvtNvWlprTn06dc5znuc5OzOM5JFJkHoEwVkxJRcRroUxsQWCjQJclOGjjha66EV00NR+FSU4yCvPJ7uFig7MscYeJ1xww13zGUfssMIMbXhq6pvQU0dTLLFVwiueeOGhAgdssMAYDXVa+L2yoyvXdK0BRpioUDAP0VcSX/GOzmfjHsPU9wi6LsLWAVezrXVLcWbkcVK9+F+/zBvUDg2z429YJgAAAABJRU5ErkJggg==',
      products: [
        {
          img: 'photos/product/GBC_Classic_Straw_Cutout-min.webp',
          nameImg: 'photos/productNameLogo/Classic.png'
        },
        {
          img: 'photos/product/GBC_Fiesta_Straw_Cutout-min.webp',
          nameImg: 'photos/productNameLogo/FiestaWatermelon.png'
        },
        {
          img: 'photos/product/GBC_Prickly_Straw_Cutout-min.webp',
          nameImg: 'photos/productNameLogo/PricklyParrot.png'
        },
        {
          img: 'photos/product/GBC_Island_Straw_Cutout-min.webp',
          nameImg: 'photos/productNameLogo/IslandPOG.png'
        },
        {
          img: 'photos/product/GBC_Berry_Straw_Cutout-min.webp',
          nameImg: 'photos/productNameLogo/BerryBlast.png'
        },
        {
          img: 'photos/product/GBC_Cherry_Straw_Cutout-min.webp',
          nameImg: 'photos/productNameLogo/CherryTart.png'
        }
      ]
    }
  },
  methods: {
    go(url) {
      window.open(url, '_blank').focus();
    }
  }
}
</script>

<style scoped>

@keyframes move-up {
  0% {
    transform: translateY(0)
  }
  to {
    transform: translateY(-50px)
  }
}

.bounce:hover {
  animation-duration: .4s;
  animation-name: move-up;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.bounce {
  filter: dropShadow(16px 16px 10px black);
}

.cursor {
  cursor: pointer;
}

</style>
